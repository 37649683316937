<template>
  <div>
    <!-- 作者：黄楚斌 -->
    <myheader></myheader>
    <!-- 实训平台背景 -->
    <div class="header-background">
      <img src="../../assets/shixunpingtai.png" alt="" />
    </div>
    <!-- 标题们 -->
    <div class="nav">
      <div class="title">
        <!-- 标题们的真正的内容 -->
        <div style="display: flex; cursor: pointer" v-for="(tl, index) in titleList" :key="'a-' + index">
          <a class="title-all" :href="'#'+tl.type">{{ tl.type }}</a>
        </div>
      </div>
      <div class="black-line"></div>
    </div>
    <!-- 标题后的内容 -->
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">平台概述</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div style="margin-left: 100px" class="wow animate__fadeInLeft"><img style="width: 365px; height: auto" src="../../assets/computer.png" alt="" /></div>
              <div class="content-type wow animate__fadeInRight">{{ types }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="平台特色" style="display: flex; justify-content: center">
      <div style="width: 1920px; background-color: #f9f9f9">
        <!-- 平台特色 大盒子 -->
        <div class="characteristic wow animate__fadeInUp">
          <div class="title-type">平台特色</div>
          <div style="display: flex; justify-content: center">
            <div class="blue-line"></div>
          </div>
          <!-- 内容 -->
          <div class="content">
            <div class="tese-box" v-for="(tese, index) in teses" :key="'a' + index">
              <!-- 每一个小块 -->
              <!-- 蓝线   @mouseout="godiscoloration" @mouseover="discoloration" :style="one" -->
              <div class="xiao-box">
                <div class="img-blue-line"></div>
                <div>
                  <img style="width: 110px; height: 90px" :src="tese.url" alt="" />
                </div>
                <div class="tese-name">{{ tese.type01 }}</div>
                <div class="tese-type">{{ tese.type02 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户场景 大盒子 -->
    <div id="应用场景" style="display: flex; justify-content: center;height: 620px;">
      <div class="userback wow animate__fadeInUp">
        <div class="title-type">应用场景</div>
        <div style="display: flex; justify-content: center">
          <div class="blue-line"></div>
        </div>
        <!-- 内容 -->
        <div style="margin-top: 50px; display: flex">
          <!-- 左边图片 -->
          <div style="margin-left: 85px">
            <img src="../../assets/work.png" alt="" />
          </div>
          <!-- 列表  -->
          <div>
            <div v-for="(cj, index) in changjing" :key="'a' + index">
              <div class="liebiao">
                <div><img style="margin-top: 10px" :src="cj.url" alt="" /></div>
                <div style="margin-left: 20px">
                  <div style="font-size: 20px; font-weight: 900">{{ cj.identity }}</div>
                  <div>{{ cj.type }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线体验 -->
    <div id="在线体验" class="zaixian" hidden>
      <div class="wow animate__fadeInUp">
        <div class="zaixian-type">在线体验</div>
        <div style="margin-top: -15px; text-align: center">
          <img class="white-line" src="../../assets/baixian.png" alt="" />
        </div>
        <div class="zaixian-type02">立即使用，体验巴拉巴拉、了解小魔仙们</div>
        <div style="text-align: center"><div class="zaixian-anniu">立即体验</div></div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- ROUTER -->
    <mybottom></mybottom>

    <!-- BOTTOM -->
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';

export default {
  components: { myheader, mybottom },
  data() {
    return {
      message: 'hello world',
      one: '',
      titleList: [
        {
          type: '平台概述',
          id: 1,
        },
        {
          type: '平台特色',
          id: 2,
        },
        {
          type: '应用场景',
          id: 3,
        }
      ],
      types:
        '职业教育在线实训平台（简称斗学网）为公司自主研发产品，是支撑人社部社会培训和教育部l+X云计算证书培训业务开展的核心平台。 平台支撑院校云计算、 大数据、 人工智能等专业开展赛教融合、 赛训融合教学改革，通过教学实训、 岗位培训、 认证评测、 竞赛等方式，引进企业真实项目，开发项目化教学资源及教材，能有效支撑 ＂岗课赛证” 综合育人，共同培养高端技术技能人才。',
      teses: [
        {
          url: require('@/assets/fangkuai.png'),
          type01: '教学平台一体设计',
          type02: '教学 实训 评测 竞赛',
        },
        {
          url: require('@/assets/dianshi.png'),
          type01: '实训资源本地调用',
          type02: '支持院校本地调用实训资源',
        },
        {
          url: require('@/assets/tu03.png'),
          type01: '课程资源模块制作',
          type02: '对接【岗课赛证】标准',
        },
        {
          url: require('@/assets/yun04.png'),
          type01: '线上资源实时分发',
          type02: '公有云实时分发最新课程资源',
        },
      ],
      changjing: [
        {
          url: require('@/assets/guanliyuan.png'),
          identity: '课程模块',
          type: '适配学校教学场景 符合教师上课流程 路径规划 模块课程 高效实验',
        },
        {
          url: require('@/assets/jiaoshi.png'),
          identity: '认证模块',
          type: '认证样卷多维评测分析 为训练提供参考 海量题库 认证点评 可视化结果',
        },
        {
          url: require('@/assets/xuesheng.png'),
          identity: '培训模块',
          type: '提供新职业云计算工程技术人员 l+X云计算平台运维与开发培训 实名认证 每日签到 过程拍照',
        },{
          url: require('@/assets/solve-one.png'),
          identity: '竞赛模块',
          type: '定期发布云计算相关趣味竞赛 锻炼学生综合素质 组队自由 获取证书 以赛促教',
        }
      ],
    };
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  created() {},
  methods: {
    //  discoloration(){
    //    this.one= 'background-color: #2e71fe;'
    //  },
    //  godiscoloration(){
    //    this.one= ''
    //  }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to th is component only -->
<style scoped lang="less">
.header-background {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  img {
    width: 100%;
  }
}
.nav {
  z-index: 999;
  position: sticky;
  top: 0px;
}
.title {
  height: 55px;
  display: flex;
  justify-content: center;
  background: #fff;
}
.black-line {
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  height: 1px;
  background-color: black;
  opacity: 0.1;
  margin: auto;
}
.title-all {
  width: 180px;
  height: 57px;
  text-align: center;
  line-height: 57px;
  font-weight: 600;
  color: #747474;
}

.title-all:hover {
  color: #2e71fe;
  background-color: #f2f2f2;
}
.box {
  width: 1200px;
  height: 550px;
  padding-top: 60px;
}
.fixed {
  display: flex;
  justify-content: center;
  width: 1200px;
}
.title-type {
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 30px;
}
.blue-line {
  width: 52px;
  height: 3px;
  background-color: #6697fe;
}
.content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.content-type {
  margin-left: 90px;
  line-height: 28px;
  width: 600px;
  font-size: 15px;
  text-indent: 25px;
  letter-spacing: 2px;
  font-weight: 500;
}
.characteristic {
  // width: 1920px;
  height: 550px;
  background-color: #f9f9f9;
  padding-top: 70px;
}
.tese-box {
  margin-right: 30px;
  display: flex;
  text-align: center;
}
.xiao-box {
  padding-top: 36px;
  text-align: center;
  width: 280px;
  height: 270px;
  background-color: #ffffff;
}
.xiao-box:hover .img-blue-line {
  background-color: blue;
}
.img-blue-line {
  width: 60px;
  height: 4px;
  background-color: #ffffff;
  margin-left: 110px;
  margin-top: -36px;
  margin-bottom: 36px;
  // text-align: center;
}
.tese-name {
  margin-top: 35px;
  font-size: 20px;
  font-weight: 1000;
}
.tese-type {
  margin-top: 20px;
  font-size: 15px;
}
.userback {
  height: 550px;
  padding-top: 60px;
}
.liebiao {
  display: flex;
  width: 460px;
  margin-left: 130px;
  margin-bottom: 47px;
}
.zaixian {
  margin-top: 47px;
  background-color: #1c50b9;
  background-image: url(../../assets/zaixiantiyan.png);
  height: 340px;
  width: auto;
  display: flex;
  justify-content: center;
  padding-top: 65px;
  // flex-direction: row-reverse;
}
.zaixian-type {
  text-align: center;
  font-size: 30px;
  color: white;
}
.white-line {
  margin-top: 15px;
  width: 50px;
}
.zaixian-type02 {
  font-size: 17px;
  margin-top: 30px;
  color: white;
  width: auto;
}
.zaixian-anniu {
  margin-top: 50px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 230px;
  margin-left: 40px;
  height: 45px;
  color: #2e71fe;
  font-size: 20px;
  font-weight: 550;
  line-height: 45px;
  text-align: center;
  // background-image: url(../../assets/yuanju01.png);
}
</style>
